import React, { useState, useEffect } from "react";
import Map from "../../components/Map/Map";
import SoonestAppointments from "../../components/SoonestAppointments/SoonestAppointments";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import DateSelector from "../../components/DateSelector/DateSelector";
import Footer from "../../components/Footer/Footer";
import routes from "../../routes/routes";
import {
  getSymptomsList,
  getNextThreeDays,
  setNextThreeDays,
} from "../../actions/covidActions";
import moment from "moment";
import momentBusinessDays from "moment-business-days";

const ClinicalAssessment: React.FC = () => {
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);

  const selectedClinic = useSelector(
    (state: RootStateOrAny) => state.mainReducer.selectedClinic
  );

  return (
    <>
      <section className="main-bg-sectoin less-space middle-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-8 col-xl-7">
              <div className="disclaimer">
                <h1>Who should visit a clinical assessment centre?</h1>

                <p>
                  You should book a clinical assessment if you know or suspect
                  that you have COVID-19 and you:
                </p>
                <ul>
                  <li>
                    have been direced by your primary care provider or other
                    health care professional; or
                  </li>
                  <li>
                    are unable to safely monitor your symptoms at home; or
                  </li>
                  <li>
                    are at higher risk of servere illness, including:{" "}
                    <ul>
                      <li>
                        immunocompromised individuals aged 18 and over
                        regardless of vaccine status
                      </li>
                      <li>unvaccinated individuals aged 60 and over</li>
                      <li>
                        unvaccinated First Nation, Inuit and Métis individuals
                        aged 50 and over
                      </li>
                      <li>
                        unvaccinated individuals aged 50 and over with one or
                        more risk fasctors.
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  You should bring a list of your medication and a list of any
                  important medical conditions to your appointment for a
                  clinical assessment.
                </p>
                <p>
                  If you have one or more of the following symptoms, you should
                  immediately call 911 or go to the emergency department:
                </p>
                <ul>
                  <li>
                    Severe difficulty breathing (struggling for each breath, can
                    only speak in single words)
                  </li>
                  <li>
                    Severe chest pain (constant tightness or crushing sensation)
                  </li>
                  <li>Feeling confused or unsure of where you are.</li>
                  <li>Losing consciousness</li>
                </ul>
                <div className="accept-disclaimer">
                  <input
                    id="check1"
                    className="checkbox-custom"
                    name="check1"
                    type="checkbox"
                    onChange={(e) => setAcceptTerms(!acceptTerms)}
                  />
                  <label htmlFor="check1" className="checkbox-custom-label">
                    <span>I accept that I have read the above disclaimer</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer
        nextLink={"/covid"}
        prevLinkTitle="Go Back"
        nextLinkTitle="Next Step"
        isAuthorized={false}
        progressLevel={45}
        hideNextStep={acceptTerms}
      />
    </>
  );
};

export default ClinicalAssessment;
