import axios, { AxiosRequestConfig } from "axios";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

export const FormInput = (props: any) => {
  const socket = useSelector(
    (state: RootStateOrAny) => state.connectToChat.socket
  );

  const primaryProfile = useSelector(
    (state: RootStateOrAny) => state.authenticateUser.primaryProfile
  );

  const officeThread = useSelector(
    (state: RootStateOrAny) => state.inboxReducer.officeThread
  );

  const mobileRef = useRef<any>(null);

  const [canMessage, setCanMessage] = useState<boolean | null>(null);

  const getOfficeDetails = async (pk: number) => {
    const token = localStorage.getItem("id_token");
    let config: AxiosRequestConfig = {
      method: "GET",
      url: `${process.env.REACT_APP_DEV_API}/office/${pk}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const is_open = await axios(config)
      .then((res) => res.data)
      .then((data) => data.is_open);
    setCanMessage(is_open);
    return is_open;
  };

  useEffect(() => {
    getOfficeDetails(officeThread.office);
  }, []);
  return (
    <div className="type-msg with-close d-none show-mobile mobile-input">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (mobileRef.current.value) {
            socket.send(
              JSON.stringify({
                type: "SEND_MESSAGE_TO_THREAD",
                threadPk: officeThread.pk,
                message: mobileRef.current.value,
                patientPk: primaryProfile.pk,
              })
            );

            mobileRef.current.value = "";
          } else {
            return;
          }
        }}
      >
        {canMessage ? (
          <input
            type="text"
            placeholder="Type your message here… (300 character limit)"
            className="common-input"
            maxLength={300}
            ref={mobileRef}
          />
        ) : canMessage == false ? (
          <p>
            Office is currently closed. if you require medical attention, go to
            nearest emergency room.
          </p>
        ) : !officeThread.archive ? (
          <>
            <input
              type="text"
              placeholder="Type your message…"
              className="common-input"
              ref={mobileRef}
            />
            <button className="primary-btn" type="submit">
              Send
            </button>
          </>
        ) : (
          <p className="disclaimer-area">
            Message has been archived. You can view your messages but to
            continue messaging, please create a new Message.
          </p>
        )}
      </form>
    </div>
  );
};
